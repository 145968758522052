import { Component, ViewChild, ElementRef, Output } from '@angular/core';
import { User } from 'src/app/models/user.model';
import { Outcome } from 'src/app/models/outcome.model';
import { Constants } from 'src/app/models/constants.model';
import { AuthService } from './login.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { EventEmitterService } from 'src/app/common/event-emitter.service';
declare var $: any;

@Component({
    selector: 'app-header-top',
    templateUrl: './header-top.component.html'
})
export class HeaderTopComponent {  
    
    model: any = {};
    user: User = new User();
    clicked = false;
    showError = false;
    userLogged: boolean = false;
    responseMessage: string;
    connectedUser: string;

    constructor(private authService: AuthService, private route: Router, private translate: TranslateService,  private eventEmitterService: EventEmitterService ) {
        this.userLogged = this.isUserLogged();
        if (this.userLogged) this.connectedUser = (JSON.parse(sessionStorage.getItem('currentUser')) as User).header;
     }

    login() {
        this.showError = false;
        this.clicked = true;
        const formData: FormData = new FormData();
        formData.append('username', this.model.username);
        formData.append('password', this.model.password);
        this.authService.login(formData).subscribe(response => this.showEsito(response));
    }

    private showEsito(outcome: Outcome<User>) {
        if (outcome.status === Constants.FAILURE) {
            this.showError = true;
            this.responseMessage = outcome.message;
        } else {
            $('#loginForm').modal('hide');
            this.userLogged = true;
            this.connectedUser = outcome.responseBody.header;
            this.translate.use((JSON.parse(sessionStorage.getItem('currentUser')) as User).language);
            this.eventEmitterService.reloadMenuEvent();
            this.route.navigate(['/about']);
        }
        this.clicked = false;
      }
    
      logout() {
        $('#logoutForm').modal('hide'); 
        this.authService.logout().subscribe(response => {
            this.eventEmitterService.reloadMenuEvent(); 
            this.userLogged = false;
            this.route.navigate(['/about'])
        });
      }

      isUserLogged(): boolean {
          return this.authService.isUserLogged() === 'true' ? true : false;
      }
}
