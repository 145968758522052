import { DocumentService } from './document.service';
import { BaseComponent } from 'src/app/common/base.component';
import { Component, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Document } from './document.model';
import { Category } from './category.model';
import { DataConverter } from 'src/app/common/data-converter.service';
import { Constants } from 'src/app/models/constants.model';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { Binary } from '@angular/compiler';
declare var $: any;

@Component({
    templateUrl: 'document.component.html'
})
export class DocumentComponent extends BaseComponent<DocumentService> implements AfterViewInit {
    isFileSelected = false;
    fileSize: string;
    fileName: string;
    document: Document;
    categories: Category[];
    clicked: boolean = false;
    formClass = 'need-validation';
    documentId: number;

    constructor(protected documentService: DocumentService, private formBuilder: FormBuilder, protected dataConvert: DataConverter,private actRoute: ActivatedRoute) {
        super(new Document(), documentService, formBuilder.group(new Document()));
        this.document = this.model as Document;
        this.documentService.getCategories().subscribe( response => {
            this.categories = response.responseBody as Category[];
        });
        this.documentId = this.actRoute.snapshot.params.id;
    }

    ngAfterViewInit(): void {
        if (this.documentId !== undefined) {
            this.documentService.getDocument(this.documentId).subscribe(outcome => {
                this.document = outcome.responseBody as Document;
                this.formGroup.patchValue({
                    title: this.document.title,
                    summary: this.document.summary,
                    date: moment(this.document.date).format('YYYY-MM-DD'),
                    type: this.document.type,
                    language: this.document.language
                  });
                  this.fileName = this.document.fileName;
                  this.isFileSelected = true;
                this.document.categories.forEach( id  => {
                    this.categories.find(cat => cat.id === (id + "")).checked = true;
                });
            });
        }
    }
    
    onFileUpload(event: any ) {
        //this.document = new Document();
        for (const file of event.target.files) {
            this.document.fileName = file.name;
            this.document.fileMultipart = file;
            this.fileSize = Math.floor(file.size/1024) + " KB";
            this.fileName = file.name;
        }
        this.isFileSelected = true;
    }

    removeFile() {
        this.isFileSelected = false; 
        this.fileSize = "";
        this.fileName = "";
    }

    updateCheckedOptions(selectedOption: string, event: any) {
        this.categories.filter(item => item.id === selectedOption).map(obj => obj.checked = event.target.checked);
     }

    onSubmit() {
        if (this.formGroup.invalid) { 
            this.formClass = "was-validated";
            this.validForm = false; 
            return; 
        } 
        if (!this.isFileSelected) {
            this.showMessage(Constants.FAILURE, 'Please, select a Document.');
            return;
        }
        if (this.categories.filter(item => item.checked === true).length === 0) {
            this.showMessage(Constants.FAILURE, 'Please, select at least one Category.');
            return;
        }
        this.clicked = true;
        this.document.title = this.formGroup.get("title").value;
        this.document.summary = this.formGroup.get("summary").value;
        this.document.date = this.formGroup.get("date").value;
        this.document.type = this.formGroup.get("type").value ? 'P' : 'R';
        this.document.language = this.formGroup.get("language").value
        this.document.categories = [];
        this.categories.filter(item => item.checked === true).forEach(element => this.document.categories.push(element.id));
        if (this.documentId === undefined) {
            this.documentService.insertDocument(this.dataConvert.Object2FormData(this.document)).subscribe(
                outcome => {
                    this.showResult(outcome);
                    this.clicked = false;
                }
            );
        } else {
            this.document.id = this.documentId as unknown as string;
            if (this.document.fileMultipart !== null) {
                this.documentService.updateDocument(this.dataConvert.Object2FormData(this.document)).subscribe(
                    outcome => {
                        this.showResult(outcome);
                        this.clicked = false;
                    }
                ); 
            } else {
                this.documentService.updateInfoDocument(this.dataConvert.Object2FormData(this.document)).subscribe(
                    outcome => {
                        this.showResult(outcome);
                        this.clicked = false;
                    }
                );   
            }
        }
    } 
}