import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { MenuService } from './menu.service';
import { Menu } from './menu.model';
import { Outcome } from 'src/app/models/outcome.model';
import { EventEmitterService } from 'src/app/common/event-emitter.service';
declare var $: any;

@Component({
    selector: 'app-left-menu',
    templateUrl: './left-menu.component.html'
})
export class LeftMenuComponent implements OnInit {

    @Output() menuClicked = new EventEmitter<Event>();
    public navItems: Menu[];

    constructor(private menuService: MenuService, private eventEmitterService: EventEmitterService) {}

    ngOnInit() {    
        if (this.eventEmitterService.subscribeEventReloadMenu==undefined) {    
          this.eventEmitterService.subscribeEventReloadMenu = this.eventEmitterService.    
                reloadMenuEventEmitter.subscribe(() => {    
                    this.loadMenu();    
          });    
        }
        this.loadMenu();    
    }   

    onClick(event: Event): void {
        $('#sidebar').removeClass('active');
        $('div.overlay').removeClass('active');
        this.menuClicked.emit(event);
      }
    
    loadMenu() {
        this.menuService.getMenu().subscribe( (outcome: Outcome<any>) => {
            this.navItems = JSON.parse(outcome.responseBody) as Menu[];
        });
    }
}
