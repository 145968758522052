import { Model } from '../models/common.model';
import { CommonService } from './common.service';
import { DataConverter } from './data-converter.service';
import { FormGroup } from '@angular/forms';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { OnInit } from '@angular/core';
import { Outcome } from '../models/outcome.model';
import { Constants } from '../models/constants.model';
declare var $: any;

export class BaseComponent<S extends CommonService<Model>> implements OnInit {

    protected validForm = false;
    public msgType: string;
    public message: string;
    protected ref: DynamicDialogRef;
    protected config: DynamicDialogConfig;
    public formGroup: FormGroup;
    protected dataConvert: DataConverter;
    protected model: Model;
    public currentPage: number;

    constructor(private object: Model, private service: S, protected baseGroup: FormGroup) {
        this.model = object;
        this.formGroup = baseGroup;
    }

    ngOnInit() {
        this.currentPage = 1;
        this.validForm = true;
        /*Subscription service for clean error message*/
        if (this.formGroup) {
            this.formGroup.valueChanges.subscribe( data => {
                this.message = null;
            });
        }
        
       $('.it-date-datepicker').datepicker({
           inputFormat: ["dd/MM/yyyy"],
           outputFormat: 'dd/MM/yyyy',
        });
    }

    isFormValid(): boolean {
        return this.validForm;
    }

    onReset(event: Event) {
        event.preventDefault();
        this.message = null;
        this.formGroup.reset();
        this.model.operation = 'I';
    }

    onClose(event: any) {
        event.preventDefault();
        this.ref.close();
    }

    onSubmit() {
        if (this.formGroup.invalid) { this.validForm = false; return; }
        switch (this.model.operation) {
            case 'I':
                this.service.insert(this.dataConvert.FormGroup2FormData(this.formGroup))
                            .subscribe(response => this.showResult(response));
                break;
            case 'E':
                this.service.update(this.dataConvert.FormGroup2FormData(this.formGroup))
                            .subscribe(response => this.showResult(response));
                break;
            case 'D':
                this.service.delete(this.dataConvert.FormGroup2FormData(this.formGroup))
                            .subscribe(response => this.showResult(response));
                break;
      }
    }

    showResult(response: Outcome<any>) {
        switch (response.status) {
            case Constants.FAILURE:
                this.msgType = Constants.MsgDANGER;
                break;
            case Constants.WARNING:
                this.msgType = Constants.MsgWARNING;
                break;
            case Constants.SUCCESS:
                this.msgType = Constants.MsgSUCCESS;
                break;
            default:
                this.msgType = Constants.MsgDANGER;
                break;
        }
        this.message = (response.message === null || response.message === undefined || response.message === '') ? 'No message from Server. Please, contact System Administrator!' : response.message;
    }

    showMessage(msgType: string, message: string) {
        switch (msgType) {
            case Constants.FAILURE:
                this.msgType = Constants.MsgDANGER;
                break;
            case Constants.WARNING:
                this.msgType = Constants.MsgWARNING;
                break;
            case Constants.SUCCESS:
                this.msgType = Constants.MsgSUCCESS;
                break;
        }
        this.message = message;
    }

    showErrorMessage(message: string) {
        this.msgType = Constants.MsgDANGER;
        this.message = message;
    }

    resetMessage() {
        this.message = null;
    }
}
