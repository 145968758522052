import { Component, ViewEncapsulation } from '@angular/core';
import { News } from './news.model';
import { NewsService } from './news.service';
declare var $: any;
@Component({
    selector: 'app-news',
    styles: ['a.news_link {color: #aa253b;}'],
    templateUrl: './news.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class NewsComponent {

   news: News[] = [];
   newsHeader: string;
   newsBody: string;
   index: number = -1;

   constructor(private newsService: NewsService) {
        this.newsService.list(null, 1).subscribe(response => {
              (response.responseBody as News[]).forEach(item => {
                  this.index++; 
                  this.news[this.index] = new News();
                  this.news[this.index].id = item.id;
                  this.news[this.index].shortTitle = item.shortTitle;
                  this.news[this.index].title = item.title;
                  this.news[this.index].summary = item.summary;
                  this.news[this.index].body = item.body;
              });
        });
    }

    openNews(id: string) {
        this.newsHeader = this.news[id].title;
        this.newsBody = this.news[id].body;
        $('#news-modal-lg').modal('show');
    }
}
