import { CommonService } from 'src/app/common/common.service';
import { Injectable } from '@angular/core';
import { Menu } from './menu.model';
import { Observable } from 'rxjs';
import { Outcome } from 'src/app/models/outcome.model';
import { map, catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class MenuService extends CommonService<Menu> {

    constructor(protected httpClient: HttpClient) {
        super('public', httpClient);
    }

    getMenu(): Observable<Outcome<any>> {
        return this.httpClient
                .get<Outcome<any>>(this.targetUrl + '/getMenu')
                .pipe(map(response => response)
                    , catchError((error: any) => this.handleError(error))
              );
    }

}
