import { Component } from '@angular/core';
import { Database } from './database.model';

@Component({
    selector: 'app-news',
    templateUrl: './database.component.html'
})
export class DatabaseComponent {

   news: Database[] = [];

   constructor() {
        this.news[0] = new Database();
        this.news[0].title = 'News Title';
        this.news[0].cardTitle = 'Main Title with more details';
        this.news[0].cardText = 'A little summary  of the news that you can read by clicking on read more';
        this.news[0].cardUrl = '';
        this.news[1] = new Database();
        this.news[1].title = 'News Title';
        this.news[1].cardTitle = 'Main Title with more details';
        this.news[1].cardText = 'A little summary  of the news that you can read by clicking on read more';
        this.news[1].cardUrl = '';
   }
}
