import { Component } from '@angular/core';

@Component({
    selector: 'app-standards',
    templateUrl: './standards.component.html'
})
export class StandardsComponent {

    clicked: boolean = false;

    DownloadStandards() {
        this.clicked = true;
        let link = document.createElement('a');
        link.setAttribute('type', 'hidden');
        link.href = 'assets/pdf/OIE-Standards.pdf';
        link.download = 'OIE-Standards';
        document.body.appendChild(link);
        link.click();
        link.remove();
        setTimeout(() =>  this.clicked = false, 2000); 
    }
}