import { Outcome } from '../models/outcome.model';
import { of, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError, map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Constants } from '../models/constants.model';

export class CommonService<T> {

    protected targetUrl: string;

    protected httpOptions = {
                headers: new HttpHeaders({'Content-Type': 'application/json'})
              };

    constructor(private baseUrl: string, protected httpClient: HttpClient) {
        this.targetUrl = environment.apiUrl + '/' + this.baseUrl;
    }

    insert(formData: FormData): Observable<Outcome<null>> {
        return this.httpClient
            .post(this.targetUrl + '/insert', formData)
            .pipe(map((response) => response as Outcome<null>)
                      , catchError((error: any) => this.handleError(error))
                  );
      }

    update(formData: FormData): Observable<Outcome<null>> {
        return this.httpClient
            .post(this.targetUrl + '/update', formData)
            .pipe(map((response) => response as Outcome<null>)
                    , catchError((error: any) => this.handleError(error))
                );
    }

    delete(formData: FormData): Observable<Outcome<null>> {
        return this.httpClient
            .post(this.targetUrl + '/delete', formData)
            .pipe(map((response) => response as Outcome<null>)
                    , catchError((error: any) => this.handleError(error))
                );
    }

    list(formData: FormData, numberOfPage: number): Observable<Outcome<T[]>> {
        return this.httpClient
                .post<Outcome<T[]>>(this.targetUrl + '/list?numberOfPage=' + numberOfPage, formData)
                .pipe(map(response => response)
                    , catchError((error: any) => this.handleError(error))
            );
    }

    lov(formData: FormData): Observable<Outcome<T[]>> {
        return this.httpClient
                .post<Outcome<T[]>>(this.targetUrl + '/lov', formData)
                .pipe(map(response => response)
                    , catchError((error: any) => this.handleError(error))
            );
    }

    protected handleError(error: Response | any) {
        let errMsg: string;
        if (error instanceof Response) {
            const body = error.json() || '';
            const err =  JSON.stringify(body);
            errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
        } else {
            errMsg = error.message ? error.message : error.toString();
        }
        return of(new Outcome(Constants.FAILURE, errMsg, null));
    }
}
