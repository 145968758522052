import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { catchError, map } from 'rxjs/operators';
import { AuthService } from '../views/layout/header/top/login.service';

@Injectable()
export class SessionInterceptor implements HttpInterceptor {

    constructor(public auth: AuthService,private router: Router) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(map((event: HttpEvent<any>) => {
            return event;
        })
      , catchError((err: any, caught) => {
            if (err instanceof HttpErrorResponse) {
                if (err.status === 403) {
                    this.router.navigateByUrl('/about');
                }
                return next.handle(request);
            }
      }));
    }
}
