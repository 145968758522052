import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NewsComponent } from './views/news/news.component';
import { AboutComponent } from './views/about/about.component';
import { HomeComponent } from './views/home/home.component';
import { MembersComponent } from './views/members/members.component';
import { UnderConstructionComponent } from './views/commons/under-construction.component';
import { DatabaseComponent } from './views/database/database.component';
import { DocumentComponent } from './views/document/document.component';
import { SearchDocumentComponent } from './views/document/search-document.component';
import { AuthGuardService } from './common/auth-guard.service';
import { StandardsComponent } from './views/standards/standards.component';
import { AssistanceComponent } from './views/assistance/assistance.component';
import { LinksComponent } from './views/links/links.component';
import { TrainingComponent } from './views/training/training.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'about', component: AboutComponent },
  { path: 'news', component: NewsComponent },
  { path: 'standards', component: StandardsComponent },
  { path: 'members', component: MembersComponent },
  { path: 'database', component: DatabaseComponent },
  { path: 'links', component: LinksComponent },
  { path: 'assistance', component: AssistanceComponent },
  { path: 'training', component: TrainingComponent},
  { path: 'document/add', component: DocumentComponent, canActivate: [AuthGuardService] },
  { path: 'document/add/:id', component: DocumentComponent, canActivate: [AuthGuardService] },
  { path: 'document/search', component: SearchDocumentComponent, canActivate: [AuthGuardService] },
  { path: '**', component: UnderConstructionComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash : true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
