import { Component, Input, AfterViewChecked, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { Document } from './document.model';
import { User } from 'src/app/models/user.model';

@Component({
    selector : 'app-card-document',
    templateUrl : './card-document.component.html',
    encapsulation: ViewEncapsulation.None
})
export class CardDocumentComponent implements AfterViewChecked {

    @Input() document: Document;
    @Input() isImportant: string;
    @Output() lock = new EventEmitter();
    @Output() edit = new EventEmitter();
    @Output() delete = new EventEmitter();
    @Output() dwnLoad = new EventEmitter();
    visible: string;
    connectedUser: User;
    editable: boolean;

    constructor(private cdRef: ChangeDetectorRef) {
        this.connectedUser = JSON.parse(sessionStorage.getItem('currentUser')) as User;
        this.editable = this.connectedUser.roles.filter(role => role.code ==='ADM' || role.code === "DOC").length>0

    }

    ngAfterViewChecked(): void {
       if (this.isImportant && this.isImportant !== 'true') {this.visible = 'invisible'; } else {this.visible = 'visible'; }
       this.cdRef.detectChanges();
    }

    lockDocument(id: string) {
        let operation = this.document.locked === 'Y' ? 'UNLOCK' : 'LOCK';
        this.lock.emit({'id': id, 'operation' : operation});
    }

    cancelDocument(id: string, locked: string) {
        if (locked !== 'Y') this.delete.emit(id);
    }

    editDocument(id: string, locked: string, userLocked: string) {
        if (locked !== 'Y' || (this.connectedUser.username === userLocked && locked === 'Y' )) this.edit.emit(id);
    }

    downloadDocument(id: string, fileName: string, fileType: string) {
        this.dwnLoad.emit({'id': id, 'fileName': fileName, 'fileType': fileType});
    }
}
