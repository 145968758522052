import { Model } from 'src/app/models/common.model';

export class Document extends Model {
   
    title = '';
    fileType: string;
    fileData: ArrayBuffer;
    fileName: string;
    summary = '';
    date = '';
    language = 'E';
    type = '';
    fileMultipart: File;
    categories: string[];
    listCategories: string;
    locked: string;
    userLocked: string;
    userHeaderLocked: string;
}