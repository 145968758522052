import { Injectable } from '@angular/core';
import { CommonService } from 'src/app/common/common.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Outcome } from 'src/app/models/outcome.model';
import { map, catchError } from 'rxjs/operators';
import { Document } from './document.model';
import { Category } from './category.model';

@Injectable()
export class DocumentService extends CommonService<Document> {

    constructor(protected httpClient: HttpClient) {
        super('document', httpClient);
    }

    insertDocument(formData: FormData): Observable<Outcome<null>> {
        return this.httpClient
            .post(this.targetUrl + '/insert',  formData)
            .pipe(map((response) => response as Outcome<null>)
                      , catchError((error: any) => this.handleError(error))
                  );
    }

    updateDocument(formData: FormData): Observable<Outcome<null>> {
        return this.httpClient
            .post(this.targetUrl + '/update',  formData)
            .pipe(map((response) => response as Outcome<null>)
                      , catchError((error: any) => this.handleError(error))
                  );
    }

    updateInfoDocument(formData: FormData): Observable<Outcome<null>> {
        return this.httpClient
            .post(this.targetUrl + '/update/info',  formData)
            .pipe(map((response) => response as Outcome<null>)
                      , catchError((error: any) => this.handleError(error))
                  );
    }

    getCategories(): Observable<Outcome<Category[]>> {
        return  this.httpClient
                    .get(this.targetUrl + '/getCategories')
                    .pipe(map((response) => response as Outcome<Category[]>)
                            , catchError((error: any) => this.handleError(error))
                        );
    }

    getDocuments(words: string, categories: string[], currentPage: number): Observable<Outcome<Document[]>> {
        return  this.httpClient
                    .get(this.targetUrl + '/getDocuments?words=' + words + "&categories=" + categories + "&numberOfPage=" + currentPage)
                    .pipe(map((response) => response as Outcome<Document[]>)
                            , catchError((error: any) => this.handleError(error))
                        );
    }

    getFileDocument(id:number): any {
        const httpOptions = {
                                'responseType'  : 'arraybuffer' as 'json'
                            };
        return this.httpClient
                   .get(this.targetUrl + '/download/' + id, httpOptions);
    }

    lockDocument(id:number, formData: FormData): Observable<Outcome<string>> {
        return this.httpClient
                   .post(this.targetUrl + '/lock/' + id, formData)
                   .pipe(map((response) => response as Outcome<null>)
                            , catchError((error: any) => this.handleError(error))
                        );
    }

    deleteDocument(id: number): Observable<Outcome<string>> {
        return this.httpClient
                   .post(this.targetUrl + '/delete/' + id, new FormData())
                   .pipe(map((response) => response as Outcome<null>)
                            , catchError((error: any) => this.handleError(error))
                        );
    }

    getDocument(id: number): Observable<Outcome<Document>> {
        return  this.httpClient
                    .get(this.targetUrl + '/getDocument/' + id)
                    .pipe(map((response) => response as Outcome<Document>)
                            , catchError((error: any) => this.handleError(error))
                        );
    }
}