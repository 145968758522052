import { Injectable, EventEmitter } from '@angular/core';    
import { Subscription } from 'rxjs/internal/Subscription';    
    
@Injectable({    
  providedIn: 'root'    
})    
export class EventEmitterService {    
    
  reloadMenuEventEmitter = new EventEmitter();    
  subscribeEventReloadMenu: Subscription;    
    
  constructor() { }    
    
  reloadMenuEvent() {    
    this.reloadMenuEventEmitter.emit();    
  }    
}    