import { Role } from './role.model';
import { SearchUser } from './search-user.model';

export class User extends SearchUser {

    password: string;
    email = '';
    language = '';
    languageName: string;
    accountLocked = false;
    avatar = '';
    accountActivationDate = '';
    accountExpirationDate = '';
    roles: Role[] = [];
}
