import { Injectable } from '@angular/core';
import { CommonService } from 'src/app/common/common.service';
import { News } from './news.model';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class NewsService extends CommonService<News> {

    constructor(protected httpClient: HttpClient) {
        super('public/news', httpClient);
    }
}