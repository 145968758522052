import { Component, AfterViewInit } from '@angular/core';
import * as $ from 'jquery';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements AfterViewInit {

    title = 'emvet';
    public menuEvent: Event;

    constructor(private translate: TranslateService, private router: Router) {
      translate.setDefaultLang('en');
      translate.use('en');
    }

  childMenuClicked(event: Event) {
    this.menuEvent = event;
  }

  ngAfterViewInit(): void {

    $( () => {
      $('#dismiss, .overlay').on('click', () => {
          $('#sidebar').removeClass('active');
          $('.overlay').removeClass('active');
      });

      $('#sidebarCollapse').on('click', () => {
          $('#sidebar').addClass('active');
          $('.overlay').addClass('active');
          $('.collapse.in').toggleClass('in');
          $('a[aria-expanded=true]').attr('aria-expanded', 'false');
      });
    });
  }
}
