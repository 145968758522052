import { Component } from '@angular/core';

@Component({
    selector: 'app-body-center',
    templateUrl: './body-center.component.html'
})
export class BodyCenterComponent {

   title1 = 'TITOLO';
   cardtitle1 = 'Titolo della sezione fdgdfgdf gfdgdfgdfg fdgdfgdfg dfgdfgd fbgfdgfdgdfgdfgdgdfgdfgdfgd';
   cardText1 = 'Sezione di dettaglio e parte descrittiva della cartella di introduzione all\'argomento';
   cardUrl = '';
}
