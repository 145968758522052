import { Component, Input, AfterViewChecked, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';

@Component({
    selector : 'app-card',
    templateUrl : './card.component.html',
    encapsulation: ViewEncapsulation.None
})
export class CardComponent implements AfterViewChecked {

    @Input() title: string;
    @Input() cardTitle: string;
    @Input() cardText: string;
    @Input() isImportant: string;
    @Output() onClick = new EventEmitter();
    visible: string;

    constructor(private cdRef: ChangeDetectorRef) {}

    ngAfterViewChecked(): void {
       if (this.isImportant && this.isImportant !== 'true') {this.visible = 'invisible'; } else {this.visible = 'visible'; }
       this.cdRef.detectChanges();
    }

    click() {
        event.preventDefault();
        this.onClick.emit();
    }
}
