import { Injectable } from '@angular/core';
import { User } from 'src/app/models/user.model';
import { environment } from 'src/environments/environment';
import { Outcome } from 'src/app/models/outcome.model';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Constants } from 'src/app/models/constants.model';

@Injectable()
export class AuthService {

    private isLoggedIn = new BehaviorSubject<boolean>(false);

    constructor(private http: HttpClient, private user: User) {}

    login(formData: FormData): Observable<Outcome<User>> {

        return this.http.post<Outcome<User>>(environment.apiUrl + '/auth/login', formData, {withCredentials: true}).pipe(map((response) => {
            const outcome =  response as Outcome<User> ;
            if (outcome.status === Constants.SUCCESS) {
                this.user.header = (outcome.responseBody).header;
                this.user.username = (outcome.responseBody).username;
                this.user.language = (outcome.responseBody).language;
                this.user.avatar = (outcome.responseBody).avatar;
                this.user.roles = (outcome.responseBody).roles;
                sessionStorage.setItem('currentUser', JSON.stringify(this.user));
                this.setLoginStatus("true");
            }
            return outcome;
          }),
          catchError((error: any) => this.handleError(error)));
    }

    logout(): Observable<Outcome<User>> {
        sessionStorage.removeItem('currentUser');
        this.setLoginStatus("false");
        return this.http.get(environment.apiUrl + '/auth/logout', {withCredentials: true}).pipe(map((response) => {
            const outcome =  response as Outcome<User>;
            if (outcome.status === Constants.SUCCESS) {
                return outcome;
            }
        }));
    }

    passwordForgotten(formData: FormData): Observable<Outcome<string>> {
        return this.http.post<Outcome<string>>(environment.apiUrl + '/users/public/forgotten', formData, {withCredentials: true}).pipe(map((response) => {
            return response as Outcome<string>;
        }));
    }

    getLoginStatus() {
        return sessionStorage.getItem('currentUser');
    }

    setLoginStatus(value: string) {
        sessionStorage.setItem("logged", value);
    }

    isUserLogged() {
        return sessionStorage.getItem("logged");
    }

    protected handleError(error: Response | any) {
        let errMsg: string;
        if (error instanceof Response) {
            const body = error.json() || '';
            const err =  JSON.stringify(body);
            errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
        } else {
            errMsg = error.message ? error.message : error.toString();
        }
        return of(new Outcome(Constants.FAILURE, errMsg, null));
    }
}
