
export class Constants {

    static readonly INSERT: string = 'I';
    static readonly EDIT: string = 'E';
    static readonly DELETE: string = 'D';
    static readonly SHOW: string = 'S';
    static readonly FIND: string = 'F';
    static readonly SUCCESS: string = 'INFO';
    static readonly WARNING: string = 'WARN';
    static readonly FAILURE: string = 'ERROR';
    static readonly MsgDANGER: string = 'danger';
    static readonly MsgSUCCESS: string = 'success';
    static readonly MsgWARNING: string = 'warning';

}
