import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Injectable()
export class DataConverter {

    constructor() {}

    Object2FormData(object: any): FormData {
        const formData = new FormData();
        for (const key in object) {
            if (object.hasOwnProperty(key)) {
                formData.append(key, (object[key] === null || object[key] === undefined ? '' : object[key]));
            }
        }
        return formData;
    }

    FormGroup2FormData(formGroup: FormGroup): FormData {
        const formData = new FormData();
        Object.keys(formGroup.controls).forEach((key: string) => {
                formData.append(key, (formGroup.controls[key].value === null || formGroup.controls[key].value === undefined ? '' : formGroup.controls[key].value));
        });
        return formData;
    }
}
