import { Component } from '@angular/core';

@Component({
    selector: 'app-members',
    templateUrl: './members.component.html'
})
export class MembersComponent {

    showIzs: boolean;
    showCensa: boolean;
    showIIAD: boolean;

    constructor() {
        this.showIzs = true;
        this.showCensa = false;
        this.showIIAD = false;
    }
    showDetails(section: string) {
        this.showIzs = false;
        this.showCensa = false;
        this.showIIAD = false;
        switch (section) {
            case 'IZS':
                this.showIzs = true;
                break;
            case 'CENSA':
                this.showCensa = true;
                break;
            case 'IIAD':
                this.showIIAD = true;
                break;
            default:
                break;
        }
        this.scrollUp();
    }

    scrollUp() {
        const scrollToTop = window.setInterval(() => {
            const pos = window.pageYOffset;
            if (pos > 0) {
                window.scrollTo(0, pos - 20); // how far to scroll on each step
            } else {
                window.clearInterval(scrollToTop);
            }
        }, 16);
    }
}
