import { Component, OnInit, Input, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';

@Component({
    selector: 'app-pagination',
    templateUrl: './pagination.component.html'
})
export class PaginationComponent implements OnInit {
    @Output() listRows = new EventEmitter();
    @Input() currentPage: number;
    @Input() totalRecords: number;

    maxPageNumber: number;
    maxPageShow = 5;


    ngOnInit() {
    }

    prevPage() {
        if (this.currentPage >= 2) {
          this.currentPage = this.currentPage - 1;
          this.listRows.emit(this.currentPage);
        }
        return false;
    }

    nextPage() {
        if (this.currentPage < this.maxPageNumber) {
          this.currentPage = this.currentPage + 1;
          this.listRows.emit(this.currentPage);
        }
        return false;
    }

    showPage(pageNumber: number) {
        this.currentPage = pageNumber;
        this.listRows.emit(this.currentPage);
        return false;
    }

    counter() {
        if (this.totalRecords) {
            this.maxPageNumber = Math.floor(this.totalRecords / this.maxPageShow) + (this.totalRecords % this.maxPageShow !== 0 ? 1 : 0);
            return new Array(this.maxPageNumber > this.maxPageShow ? this.maxPageShow : this.maxPageNumber);
        }
    }

    isSelected(currentPage: number): string {
        return (this.currentPage === currentPage) ? 'page' : '';
    }
}
