import { Data } from './data.model';

export class Outcome<T> {

    status: string;
    message: string;
    data: Data;
    responseBody: T;

    constructor(status: string, message: string, responseBody: T) {
        this.status = status;
        this.message = message;
        this.responseBody = responseBody;
    }
}
