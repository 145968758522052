import { Component } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
@Component({
    selector: 'app-home',
    templateUrl: './home.component.html'
})
export class HomeComponent {

}

