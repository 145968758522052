import { BaseComponent } from 'src/app/common/base.component';
import { Component } from '@angular/core';
import { Document } from './document.model';
import { DocumentService } from './document.service';
import { FormBuilder } from '@angular/forms';
import { DataConverter } from 'src/app/common/data-converter.service';
import { Category } from './category.model';
import { Constants } from 'src/app/models/constants.model';
import { saveAs } from 'file-saver/dist/FileSaver';
import { Router } from '@angular/router';
declare var $: any;

@Component({
    templateUrl: 'search-document.component.html'
})
export class SearchDocumentComponent extends BaseComponent<DocumentService> {
    
    categories: Category[];
    documents: Document[];
    selectedCategories: string[] = [];
    words: string = '';
    resultSize: number;
    showList: boolean = false;
    querying: boolean;
    documentToDelete: number;

    constructor(protected documentService: DocumentService, private formBuilder: FormBuilder, protected dataConvert: DataConverter, public router: Router) {
        super(new Document(), documentService, formBuilder.group(new Document()));
        this.currentPage = 1;
        this.documentService.getCategories().subscribe( response => this.categories = response.responseBody as Category[]);
        this.searchDocument(this.words, this.selectedCategories, this.currentPage);
       
    }

    updateCheckedOptions(selectedOption: string, event: any) {
        this.categories.filter(item => item.id === selectedOption).map(obj => obj.checked = event.target.checked);
        this.selectedCategories = [];
        this.categories.forEach( category => {
            if (category.checked) this.selectedCategories.push(category.id);
        });
        this.searchDocument(this.words, this.selectedCategories, this.currentPage);
     }

     searchDocument(words: string, categories: string[], currentPage: number) {
        this.resetMessage();
        this.querying = true;
        this.documentService.getDocuments(words, categories, currentPage).subscribe( response => {
            if (response.status === Constants.SUCCESS) {
                this.resultSize = response.data.resultSize;
                this.documents = response.responseBody as Document[];
            } else {
                this.showErrorMessage(response.message);
            }
            this.querying = false;
        });
     }

     listDocument(numberOfPage: number) {
         this.currentPage = numberOfPage;
         this.searchDocument(this.words, this.selectedCategories, this.currentPage);
     }

     findDocument(event: any) {
        this.words = event.target.value;
        this.searchDocument(this.words, this.selectedCategories, this.currentPage);
     }

     downloadDocument(event: any) {
        this.querying = true;
        this.documentService.getFileDocument(event.id).subscribe( outcome => {
            var blob = new Blob([outcome], { type: event.fileType});
            saveAs(blob, event.fileName);
            this.querying = false;
        });
        return false;
     }

     showAsList(event: any) {
        this.showList = event.target.checked;
     }

     lockDocument(event: any) {
        this.querying = true;
        const formData = new FormData();
        formData.append('operation', event.operation);
        this.documentService.lockDocument(event.id, formData).subscribe(outcome => {
            if (outcome.status === Constants.SUCCESS) {this.listDocument(this.currentPage);}
            else this.showErrorMessage(outcome.message);
            this.querying = false;
        });
         return false;
     }

     editDocument(event:any) {
         this.router.navigateByUrl('document/add/' + event);
         return false;
     }

     confirmDelete(event:any) {
        $('#deleteDialog').modal('show');
        this.documentToDelete = event;
    }

    deleteDocument() {
        this.querying = true;
        this.documentService.deleteDocument( this.documentToDelete).subscribe(outcome => {
            $('#deleteDialog').modal('hide');
            if (outcome.status === Constants.SUCCESS) {this.listDocument(this.currentPage);}
            this.showResult(outcome);
            this.querying = false;
        });
        return false;
    }
}