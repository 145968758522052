import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
/* i18n Modules */
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

import { DialogModule } from 'primeng/dialog';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LeftMenuComponent } from './views/layout/menu/left-menu.component';
import { HeaderComponent } from './views/layout/header/header.component';
import { HeaderTopComponent } from './views/layout/header/top/header-top.component';
import { HeaderCenterComponent } from './views/layout/header/center/header-center.component';
import { HeaderMenuComponent } from './views/layout/header/bottom/header-menu.compoent';
import { FooterComponent } from './views/layout/footer/footer.component';
import { CookieMsgComponent } from './views/layout/footer/cookie-msg.component';
import { FooterBottomComponent } from './views/layout/footer/bottom/footer-bottom.component';
import { FooterSectionUpperComponent } from './views/layout/footer/sections/footer-section-upper.component';
import { FooterSectionCenterComponent } from './views/layout/footer/sections/footer-section-center.component';
import { FooterSectionBottomComponent } from './views/layout/footer/sections/footer-section-bottom.component';
import { BreadcrumbComponent } from './views/layout/breadcrumb/breadcrumb.component';
import { BodyTopComponent } from './views/layout/body/body-top/body-top.component';
import { BodyCenterComponent } from './views/layout/body/body-center/body-center.component';
import { BodyBottomComponent } from './views/layout/body/body-bottom/body-bottom.component';
import { MenuService } from './views/layout/menu/menu.service';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { CardComponent } from './views/layout/card/card.component';
import { NewsComponent } from './views/news/news.component';
import { AboutComponent } from './views/about/about.component';
import { MembersComponent } from './views/members/members.component';
import { HomeComponent } from './views/home/home.component';
import { UnderConstructionComponent } from './views/commons/under-construction.component';
import { DatabaseComponent } from './views/database/database.component';
import { AuthService } from './views/layout/header/top/login.service';
import { User } from './models/user.model';
import { SessionInterceptor } from './common/session.interceptor';
import { JwtInterceptor } from './common/jwt.interceptor';
import { DocumentComponent } from './views/document/document.component';
import { DocumentService } from './views/document/document.service';
import { FileUploadModule } from 'primeng/fileupload';
import { DataConverter } from './common/data-converter.service';
import { SearchDocumentComponent } from './views/document/search-document.component';
import { PaginationComponent } from './views/commons/pagination.component';
import { CardDocumentComponent } from './views/document/card-document.component';
import { AuthGuardService } from './common/auth-guard.service';
import { StandardsComponent } from './views/standards/standards.component';
import { AssistanceComponent } from './views/assistance/assistance.component';
import { NewsService } from './views/news/news.service';
import { TrainingComponent } from './views/training/training.component';

export function HttpLoaderFactory(http: HttpClient) {
 return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    LeftMenuComponent,
    HeaderComponent,
    HeaderTopComponent,
    HeaderCenterComponent,
    HeaderMenuComponent,
    BreadcrumbComponent,
    BodyTopComponent,
    BodyCenterComponent,
    BodyBottomComponent,
    FooterComponent,
    FooterSectionUpperComponent,
    FooterSectionCenterComponent,
    FooterSectionBottomComponent,
    FooterBottomComponent,
    CookieMsgComponent,
    CardComponent,
    NewsComponent,
    AboutComponent,
    MembersComponent,
    DocumentComponent,
    HomeComponent,
    DatabaseComponent,
    SearchDocumentComponent,
    CardDocumentComponent,
    PaginationComponent,
    StandardsComponent,
    AssistanceComponent,
    TrainingComponent,
    UnderConstructionComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    DialogModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    FileUploadModule,
    TranslateModule.forRoot({
      loader: {
                  provide: TranslateLoader,
                  useFactory: HttpLoaderFactory,
                  deps: [HttpClient]
      }
    })
  ],
  providers: [
    MenuService,
    AuthService,
    AuthGuardService,
    DocumentService,
    User,
    NewsService,
    DataConverter,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SessionInterceptor,
      multi: true
    },
    HttpClient
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
